export default {
    errors: false,
    spinner: false,
    submit: false,
    getErrors: {
        hasMessage: false,
        color: "danger",
        message: "",
        messageArray: "",
    },
    optDesconto: [
        { value: 0, tipo: "%" },
        { value: 1, tipo: "R$" },
    ],
    money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
    },
    unidades: [
        { value: "un", text: "un (Unitário)" },
        { value: "kg", text: "kg (Quilo)" },
        { value: "l", text: "l (Litro)" },
        { value: "m", text: "m (Metro)" },
    ],
    qtdFloatFormat: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 3,
        masked: false,
    },
    qtdIntFormat: {
        decimal: ",",
        thousands: "",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false,
    },
    options: {
        hora: {
            delimiters: [":"],
            blocks: [2, 2],
            numericOnly: true,
            time: true,
            timePattern: ["h", "m"],
        },
        desconto: {
            numeral: true,
            numeralPositiveOnly: true,
            numericOnly: true,
        },
    },

}
