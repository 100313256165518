<template>
  <div class="odontograma">
    <div class="odontograma-sup">
      <div class="odontograma-dente" @click="superior = !superior, alteraTodos(1, superior, inferior)">
        <div class="odontogram-number ">
          <b-button :variant="superior === true ? 'gradient-success' : 'secondary'" size="sm"
                    style="padding: 4px;position: relative;top: -5px">SUP
          </b-button>
        </div>
      </div>
      <div class="odontograma-dente"
           @click="!superior ? imagemDentesSup.dente18 = !imagemDentesSup.dente18 : '', alteraDente(18, imagemDentesSup.dente18)">
        <div class="odontograma-dente-imagem dente-18 tooltipstered">
          <img v-if="!imagemDentesSup.dente18" alt="" src="../../assets/images/dentes/dente-18.png"
               width="24">
          <img v-else alt="" src="../../assets/images/dentes/dente-18-selecionado.png" width="24">
        </div>
        <div class="odontogram-number odontogram-number-18">
          18
        </div>
      </div>
      <div class="odontograma-dente"
           @click="!superior ? imagemDentesSup.dente17 = !imagemDentesSup.dente17 : '', alteraDente(17, imagemDentesSup.dente17)">
        <div class="odontograma-dente-imagem dente-17 tooltipstered">
          <img v-if="!imagemDentesSup.dente17" :width="26"
               src="../../assets/images/dentes/dente-17.png">
          <img v-else :width="26" src="../../assets/images/dentes/dente-17-selecionado.png">
        </div>
        <div class="odontogram-number odontogram-number-17">
          17
        </div>
      </div>
      <div class="odontograma-dente"
           @click="!superior ? imagemDentesSup.dente16 = !imagemDentesSup.dente16 : '', alteraDente(16, imagemDentesSup.dente16)">
        <div class="odontograma-dente-imagem dente-16 odontogram-tooth-done tooltipstered">
          <img v-if="!imagemDentesSup.dente16" src="../../assets/images/dentes/dente-16.png"
               width="28">
          <img v-else src="../../assets/images/dentes/dente-16-selecionado.png" width="28">
        </div>
        <div class="odontogram-number odontogram-number-16">
          16
        </div>
      </div>
      <div class="odontograma-dente"
           @click="!superior ? imagemDentesSup.dente15 = !imagemDentesSup.dente15 : '', alteraDente(15, imagemDentesSup.dente15)">
        <div class="odontograma-dente-imagem dente-15 tooltipstered">
          <img v-if="!imagemDentesSup.dente15" src="../../assets/images/dentes/dente-15.png"
               width="18">
          <img v-else src="../../assets/images/dentes/dente-15-selecionado.png" width="18">
        </div>
        <div class="odontogram-number odontogram-number-15">
          15
        </div>
      </div>
      <div class="odontograma-dente"
           @click="!superior ? imagemDentesSup.dente14 = !imagemDentesSup.dente14 : '', alteraDente(14, imagemDentesSup.dente14)">
        <div class="odontograma-dente-imagem dente-14 tooltipstered">
          <img v-if="!imagemDentesSup.dente14" src="../../assets/images/dentes/dente-14.png"
               width="18">
          <img v-else src="../../assets/images/dentes/dente-14-selecionado.png" width="18">
        </div>
        <div class="odontogram-number odontogram-number-14">
          14
        </div>
      </div>
      <div class="odontograma-dente"
           @click="!superior ? imagemDentesSup.dente13 = !imagemDentesSup.dente13 : '', alteraDente(13, imagemDentesSup.dente13)">
        <div class="odontograma-dente-imagem dente-13 tooltipstered">
          <img v-if="!imagemDentesSup.dente13" src="../../assets/images/dentes/dente-13.png"
               width="18">
          <img v-else src="../../assets/images/dentes/dente-13-selecionado.png" width="18">
        </div>
        <div class="odontogram-number odontogram-number-13">
          13
        </div>
      </div>
      <div class="odontograma-dente"
           @click="!superior ? imagemDentesSup.dente12 = !imagemDentesSup.dente12 : '', alteraDente(12, imagemDentesSup.dente12)">
        <div class="odontograma-dente-imagem dente-12 tooltipstered">
          <img v-if="!imagemDentesSup.dente12" src="../../assets/images/dentes/dente-12.png"
               width="18">
          <img v-else src="../../assets/images/dentes/dente-12-selecionado.png" width="18">
        </div>
        <div class="odontogram-number odontogram-number-12">
          12
        </div>
      </div>
      <div class="odontograma-dente"
           @click="!superior ? imagemDentesSup.dente11 = !imagemDentesSup.dente11 : '', alteraDente(11, imagemDentesSup.dente11)">
        <div class="odontograma-dente-imagem dente-11 tooltipstered">
          <img v-if="!imagemDentesSup.dente11" src="../../assets/images/dentes/dente-11.png"
               width="20">
          <img v-else src="../../assets/images/dentes/dente-11-selecionado.png" width="20">
        </div>
        <div class="odontogram-number odontogram-number-11">
          11
        </div>
      </div>
      <div class="odontograma-dente"
           @click="!superior ? imagemDentesSup.dente21 = !imagemDentesSup.dente21 : '', alteraDente(21, imagemDentesSup.dente21)">
        <div class="odontograma-dente-imagem dente-21 tooltipstered">
          <img v-if="!imagemDentesSup.dente21" src="../../assets/images/dentes/dente-21.png"
               width="20">
          <img v-else src="../../assets/images/dentes/dente-21-selecionado.png" width="20">
        </div>
        <div class="odontogram-number odontogram-number-21">
          21
        </div>
      </div>
      <div class="odontograma-dente"
           @click="!superior ? imagemDentesSup.dente22 = !imagemDentesSup.dente22 : '', alteraDente(22, imagemDentesSup.dente22)">
        <div class="odontograma-dente-imagem dente-22 tooltipstered">
          <img v-if="!imagemDentesSup.dente22" src="../../assets/images/dentes/dente-22.png"
               width="18">
          <img v-else src="../../assets/images/dentes/dente-22-selecionado.png" width="18">
        </div>
        <div class="odontogram-number odontogram-number-22">
          22
        </div>
      </div>
      <div class="odontograma-dente"
           @click="!superior ? imagemDentesSup.dente23 = !imagemDentesSup.dente23 : '', alteraDente(23, imagemDentesSup.dente23)">
        <div class="odontograma-dente-imagem dente-23 tooltipstered">
          <img v-if="!imagemDentesSup.dente23" src="../../assets/images/dentes/dente-23.png"
               width="18">
          <img v-else src="../../assets/images/dentes/dente-23-selecionado.png" width="18">
        </div>
        <div class="odontogram-number odontogram-number-23">
          23
        </div>
      </div>
      <div class="odontograma-dente"
           @click="!superior ? imagemDentesSup.dente24 = !imagemDentesSup.dente24 : '', alteraDente(24, imagemDentesSup.dente24)">
        <div class="odontograma-dente-imagem dente-24 tooltipstered">
          <img v-if="!imagemDentesSup.dente24" src="../../assets/images/dentes/dente-24.png"
               width="18">
          <img v-else src="../../assets/images/dentes/dente-24-selecionado.png" width="18">
        </div>
        <div class="odontogram-number odontogram-number-24">
          24
        </div>
      </div>
      <div class="odontograma-dente"
           @click="!superior ? imagemDentesSup.dente25 = !imagemDentesSup.dente25 : '', alteraDente(25, imagemDentesSup.dente25)">
        <div class="odontograma-dente-imagem dente-25 tooltipstered">
          <img v-if="!imagemDentesSup.dente25" src="../../assets/images/dentes/dente-25.png"
               width="20">
          <img v-else src="../../assets/images/dentes/dente-25-selecionado.png" width="20">
        </div>
        <div class="odontogram-number odontogram-number-25">
          25
        </div>
      </div>
      <div class="odontograma-dente"
           @click="!superior ? imagemDentesSup.dente26= !imagemDentesSup.dente26 : '', alteraDente(26, imagemDentesSup.dente26)">
        <div class="odontograma-dente-imagem dente-26 odontogram-tooth-on-process tooltipstered">
          <img v-if="!imagemDentesSup.dente26" src="../../assets/images/dentes/dente-26.png"
               width="24">
          <img v-else src="../../assets/images/dentes/dente-26-selecionado.png" width="24">
        </div>
        <div class="odontogram-number odontogram-number-26">
          26
        </div>
      </div>
      <div class="odontograma-dente"
           @click="!superior ? imagemDentesSup.dente27 = !imagemDentesSup.dente27 : '', alteraDente(27, imagemDentesSup.dente27)">
        <div class="odontograma-dente-imagem dente-27 tooltipstered">
          <img v-if="!imagemDentesSup.dente27" src="../../assets/images/dentes/dente-27.png"
               width="28">
          <img v-else src="../../assets/images/dentes/dente-27-selecionado.png" width="28">
        </div>
        <div class="odontogram-number odontogram-number-27">
          27
        </div>
      </div>
      <div class="odontograma-dente"
           @click="!superior ? imagemDentesSup.dente28 = !imagemDentesSup.dente28 : '', alteraDente(28, imagemDentesSup.dente28)">
        <div class="odontograma-dente-imagem dente-28 tooltipstered">
          <img v-if="!imagemDentesSup.dente28" src="../../assets/images/dentes/dente-28.png"
               width="25">
          <img v-else src="../../assets/images/dentes/dente-28-selecionado.png" width="25">


        </div>
        <div class="odontogram-number odontogram-number-28">
          28
        </div>
      </div>
    </div>
    <div class="odontograma-inf">
      <div class="odontograma-dente" @click="inferior = !inferior, alteraTodos(2, superior, inferior)">
        <div class="odontogram-number ">
          <b-button :variant="inferior === true ? 'gradient-success' : 'secondary'" active size="sm"
                    style="padding: 4px;position: relative;top: -40px;left: -4px"> INF
          </b-button>
        </div>
      </div>
      <div class="odontograma-dente-inf odontograma-dente-inf-48"
           @click="!inferior ? imagemDentesInf.dente48 = !imagemDentesInf.dente48 : '', alteraDente(48, imagemDentesInf.dente48)">
        <div class="odontogram-number odontogram-number-48">
          48
        </div>
        <div class="odontograma-dente-imagem odontogram-tooth-image-48 tooltipstered">
          <img v-if="!imagemDentesInf.dente48" src="../../assets/images/dentes/dente-48.png"
               width="28">
          <img v-else src="../../assets/images/dentes/dente-48-selecionado.png" width="28">
        </div>
      </div>
      <div class="odontograma-dente-inf odontograma-dente-inf-47"
           @click="!inferior ? imagemDentesInf.dente47 = !imagemDentesInf.dente47 : '', alteraDente(47, imagemDentesInf.dente47)">
        <div class="odontogram-number odontogram-number-47">
          47
        </div>
        <div class="odontograma-dente-imagem odontogram-tooth-image-47 tooltipstered">
          <img v-if="!imagemDentesInf.dente47" src="../../assets/images/dentes/dente-47.png"
               width="25">
          <img v-else src="../../assets/images/dentes/dente-47-selecionado.png" width="25">
        </div>
      </div>
      <div class="odontograma-dente-inf odontograma-dente-inf-46"
           @click="!inferior ? imagemDentesInf.dente46 = !imagemDentesInf.dente46 : '', alteraDente(46, imagemDentesInf.dente46)">
        <div class="odontogram-number odontogram-number-46">
          46
        </div>
        <div class="odontograma-dente-imagem odontogram-tooth-image-46 tooltipstered">
          <img v-if="!imagemDentesInf.dente46" src="../../assets/images/dentes/dente-46.png"
               width="28">
          <img v-else src="../../assets/images/dentes/dente-46-selecionado.png" width="28">
        </div>
      </div>
      <div class="odontograma-dente-inf odontograma-dente-inf-45"
           @click="!inferior ? imagemDentesInf.dente45 = !imagemDentesInf.dente45 : '', alteraDente(45, imagemDentesInf.dente45)">
        <div class="odontogram-number odontogram-number-45">
          45
        </div>
        <div class="odontograma-dente-imagem odontogram-tooth-image-45 tooltipstered">
          <img v-if="!imagemDentesInf.dente45" src="../../assets/images/dentes/dente-45.png"
               width="20">
          <img v-else src="../../assets/images/dentes/dente-45-selecionado.png" width="20">
        </div>
      </div>
      <div class="odontograma-dente-inf odontograma-dente-inf-44"
           @click="!inferior ? imagemDentesInf.dente44 = !imagemDentesInf.dente44 : '', alteraDente(44, imagemDentesInf.dente44)">
        <div class="odontogram-number odontogram-number-44">
          44
        </div>
        <div class="odontograma-dente-imagem odontogram-tooth-image-44 tooltipstered">
          <img v-if="!imagemDentesInf.dente44" src="../../assets/images/dentes/dente-44.png"
               width="20">
          <img v-else src="../../assets/images/dentes/dente-44-selecionado.png" width="20">
        </div>
      </div>
      <div class="odontograma-dente-inf odontograma-dente-inf-43"
           @click="!inferior ? imagemDentesInf.dente43 = !imagemDentesInf.dente43 : '', alteraDente(43, imagemDentesInf.dente43)">
        <div class="odontogram-number odontogram-number-43">
          43
        </div>
        <div class="odontograma-dente-imagem odontogram-tooth-image-43 tooltipstered">
          <img v-if="!imagemDentesInf.dente43" src="../../assets/images/dentes/dente-43.png"
               width="20">
          <img v-else src="../../assets/images/dentes/dente-43-selecionado.png" width="20">
        </div>
      </div>
      <div class="odontograma-dente-inf odontograma-dente-inf-42" style="margin-left: -3px"
           @click="!inferior ? imagemDentesInf.dente42 = !imagemDentesInf.dente42 : '', alteraDente(42, imagemDentesInf.dente42)">
        <div class="odontogram-number odontogram-number-42">
          42
        </div>
        <div class="odontograma-dente-imagem odontogram-tooth-image-42 tooltipstered">
          <img v-if="!imagemDentesInf.dente42" src="../../assets/images/dentes/dente-42.png"
               width="16">
          <img v-else src="../../assets/images/dentes/dente-42-selecionado.png" width="16">
        </div>
      </div>
      <div class="odontograma-dente-inf odontograma-dente-inf-41"
           @click="!inferior ? imagemDentesInf.dente41 = !imagemDentesInf.dente41 : '', alteraDente(41, imagemDentesInf.dente41)">
        <div class="odontogram-number odontogram-number-41">
          41
        </div>
        <div class="odontograma-dente-imagem odontogram-tooth-image-41 tooltipstered">
          <img v-if="!imagemDentesInf.dente41" src="../../assets/images/dentes/dente-41.png"
               width="15">
          <img v-else src="../../assets/images/dentes/dente-41-selecionado.png" width="15">
        </div>
      </div>
      <div class="odontograma-dente-inf odontograma-dente-inf-31"
           @click="!inferior ? imagemDentesInf.dente31 = !imagemDentesInf.dente31 : '', alteraDente(31, imagemDentesInf.dente31)">
        <div class="odontogram-number odontogram-number-31">
          31
        </div>
        <div class="odontograma-dente-imagem odontogram-tooth-image-31 tooltipstered">
          <img v-if="!imagemDentesInf.dente31" src="../../assets/images/dentes/dente-31.png"
               width="15">
          <img v-else src="../../assets/images/dentes/dente-31-selecionado.png" width="15">
        </div>
      </div>
      <div class="odontograma-dente-inf odontograma-dente-inf-32"
           @click="!inferior ? imagemDentesInf.dente32 = !imagemDentesInf.dente32 : '', alteraDente(32, imagemDentesInf.dente32)">
        <div class="odontogram-number odontogram-number-32">
          32
        </div>
        <div class="odontograma-dente-imagem odontogram-tooth-image-32 tooltipstered">
          <img v-if="!imagemDentesInf.dente32" src="../../assets/images/dentes/dente-32.png"
               width="16">
          <img v-else src="../../assets/images/dentes/dente-32-selecionado.png" width="16">
        </div>
      </div>
      <div class="odontograma-dente-inf odontograma-dente-inf-33"
           @click="!inferior ? imagemDentesInf.dente33 = !imagemDentesInf.dente33 : '', alteraDente(33, imagemDentesInf.dente33)">
        <div class="odontogram-number odontogram-number-33">
          33
        </div>
        <div class="odontograma-dente-imagem odontogram-tooth-image-33 tooltipstered">
          <img v-if="!imagemDentesInf.dente33" src="../../assets/images/dentes/dente-33.png"
               width="19">
          <img v-else src="../../assets/images/dentes/dente-33-selecionado.png" width="19">
        </div>
      </div>
      <div class="odontograma-dente-inf odontograma-dente-inf-34"
           @click="!inferior ? imagemDentesInf.dente34 = !imagemDentesInf.dente34 : '', alteraDente(34, imagemDentesInf.dente34)">
        <div class="odontogram-number odontogram-number-34">
          34
        </div>
        <div class="odontograma-dente-imagem odontogram-tooth-image-34 tooltipstered">
          <img v-if="!imagemDentesInf.dente34" src="../../assets/images/dentes/dente-34.png"
               width="19">
          <img v-else src="../../assets/images/dentes/dente-34-selecionado.png" width="19">
        </div>
      </div>
      <div class="odontograma-dente-inf odontograma-dente-inf-35"
           @click="!inferior ? imagemDentesInf.dente35 = !imagemDentesInf.dente35 : '', alteraDente(35, imagemDentesInf.dente35)">
        <div class="odontogram-number odontogram-number-35">
          35
        </div>
        <div class="odontograma-dente-imagem odontogram-tooth-image-35 tooltipstered">
          <img v-if="!imagemDentesInf.dente35" src="../../assets/images/dentes/dente-35.png"
               width="20">
          <img v-else src="../../assets/images/dentes/dente-35-selecionado.png" width="20">
        </div>
      </div>
      <div class="odontograma-dente-inf odontograma-dente-inf-36"
           @click="!inferior ? imagemDentesInf.dente36 = !imagemDentesInf.dente36 : '', alteraDente(36, imagemDentesInf.dente36)">
        <div class="odontogram-number odontogram-number-36">
          36
        </div>
        <div class="odontograma-dente-imagem odontogram-tooth-image-36 tooltipstered">
          <img v-if="!imagemDentesInf.dente36" src="../../assets/images/dentes/dente-36.png"
               width="28">
          <img v-else src="../../assets/images/dentes/dente-36-selecionado.png" width="28">
        </div>
      </div>
      <div class="odontograma-dente-inf odontograma-dente-inf-37"
           @click="!inferior ? imagemDentesInf.dente37 = !imagemDentesInf.dente37 : '', alteraDente(37, imagemDentesInf.dente37)">
        <div class="odontogram-number odontogram-number-37">
          37
        </div>
        <div class="odontograma-dente-imagem odontogram-tooth-image-37 tooltipstered">
          <img v-if="!imagemDentesInf.dente37" src="../../assets/images/dentes/dente-37.png"
               width="27">
          <img v-else src="../../assets/images/dentes/dente-37-selecionado.png" width="27">
        </div>
      </div>
      <div class="odontograma-dente-inf odontograma-dente-inf-38"
           @click="!inferior ? imagemDentesInf.dente38 = !imagemDentesInf.dente38 : '', alteraDente(38, imagemDentesInf.dente38)">
        <div class="odontograma-number odontogram-number-38">
          38
        </div>
        <div class="odontograma-dente-imagem odontogram-tooth-image-38 tooltipstered">
          <img v-if="!imagemDentesInf.dente38" src="../../assets/images/dentes/dente-38.png"
               width="28">
          <img v-else src="../../assets/images/dentes/dente-38-selecionado.png" width="28">
        </div>
      </div>
    </div>

  </div>


</template>

<script>

import {BButton} from 'bootstrap-vue'

export default {

  props: ['dentesSelecionados', 'dentesEditar'],

  components: {
    BButton,
  },


  data() {
    return {
      tipoArcada: 1,
      tamanhoDentesImg: 28,
      imagemDentesSup: {
        dente18: false,
        dente17: false,
        dente16: false,
        dente15: false,
        dente14: false,
        dente13: false,
        dente12: false,
        dente11: false,
        dente21: false,
        dente22: false,
        dente23: false,
        dente24: false,
        dente25: false,
        dente26: false,
        dente27: false,
        dente28: false,
      },
      imagemDentesInf: {
        dente48: false,
        dente47: false,
        dente46: false,
        dente45: false,
        dente44: false,
        dente43: false,
        dente42: false,
        dente41: false,
        dente31: false,
        dente32: false,
        dente33: false,
        dente34: false,
        dente35: false,
        dente36: false,
        dente37: false,
        dente38: false,
      },
      superior: false,
      inferior: false,
      dentesSelInf: [],
      dentesSelSup: []

    }
  },

  methods: {

    /**
     * @var dente
     * 1 - Superior
     * 2 - inferior
     * **/

    alteraTodos(dente, superior, inferior) {

      let resultado = []

      if (dente === 1) {

        console.log('dentes inferiores', this.dentesSelInf)
        this.dentesSelSup = []

        if (superior) {

          this.selecionaDentesSup()
          this.dentesSelSup = ['SUP']

          resultado = [
            'SUP',
            ...this.dentesSelInf
          ]

        } else {

          this.desativaDentesSup()
          this.dentesSelSup = []
          resultado = this.dentesSelInf

        }


      } else {

        this.dentesSelInf = []

        if (inferior) {

          this.selecionaDentesInf()

          resultado = [
            'INF',
            ...this.dentesSelSup
          ]

          this.dentesSelInf = ['INF']

        } else {
          this.desativaDentesInf()
          resultado = this.dentesSelSup
          this.dentesSelInf = []

        }

      }

      this.$emit('denteSelecionado', resultado)
    },
    alteraDente(numDente, selecionado) {

      // Adiciona na Lista

      let index = this.dentesSelecionados.indexOf(numDente)

      if (selecionado) {
        if (index === -1)
          if (numDente > 30) {
            if (!this.inferior) {

              this.dentesSelInf.push(numDente)
            }
          } else {
            if (!this.superior) {
              this.dentesSelSup.push(numDente)
            }
          }
      } else {

        if (numDente > 30) {
          if (!this.inferior) {

            let indexInf = this.dentesSelInf.indexOf(numDente)
            this.dentesSelInf.splice(indexInf, 1)

          }
        } else {
          if (!this.superior) {

            let indexSup = this.dentesSelSup.indexOf(numDente)
            this.dentesSelSup.splice(indexSup, 1)

          }
        }

      }


      let result =
          [
            ...this.dentesSelSup,
            ...this.dentesSelInf
          ]


      this.$emit('denteSelecionado', result.sort())


    },
    selecionaDentesEditar(){
      console.log('selecionaDentesEditar', this.dentesSelecionados)
      // ao editar uma entrada, roda a  funcao para setar dentes superiores e inferiores

      this.desativaDentesInf()
      this.desativaDentesSup()
      this.dentesSelSup = []
      this.dentesSelInf =[]
      let dentes = this.dentesSelecionados

      if(typeof this.dentesSelecionados == 'string'){
        dentes = this.dentesSelecionados?.split(',')
      }

      if(dentes) {
        dentes.forEach( (v) => {
          if(v <= 28) {
            this.imagemDentesSup['dente'+v] = true

            this.dentesSelSup.push(parseInt(v))
          }else if(v > 28 || v <= 38){
            this.imagemDentesInf['dente'+v] = true
            this.dentesSelInf.push(parseInt(v))
          }else if(v == 'INF'){
            this.selecionaDentesInf()
            this.inferior = true
            this.dentesSelInf = ["INF"]
          }
          else if(v == 'SUP'){
            this.selecionaDentesSup()
            this.superior = true
            this.dentesSelSup = ["SUP"]
          }

        } )
      }

    },


    selecionaDentesInf() {
      this.imagemDentesInf = {
        dente48: true,
        dente47: true,
        dente46: true,
        dente45: true,
        dente44: true,
        dente43: true,
        dente42: true,
        dente41: true,
        dente31: true,
        dente32: true,
        dente33: true,
        dente34: true,
        dente35: true,
        dente36: true,
        dente37: true,
        dente38: true,
      }
    },
    selecionaDentesSup() {
      this.imagemDentesSup = {
        dente18: true,
        dente17: true,
        dente16: true,
        dente15: true,
        dente14: true,
        dente13: true,
        dente12: true,
        dente11: true,
        dente21: true,
        dente22: true,
        dente23: true,
        dente24: true,
        dente25: true,
        dente26: true,
        dente27: true,
        dente28: true,
      }
    },
    desativaDentesInf() {
      this.imagemDentesInf = {
        dente48: false,
        dente47: false,
        dente46: false,
        dente45: false,
        dente44: false,
        dente43: false,
        dente42: false,
        dente41: false,
        dente31: false,
        dente32: false,
        dente33: false,
        dente34: false,
        dente35: false,
        dente36: false,
        dente37: false,
        dente38: false,
      }
    },
    desativaDentesSup() {
      this.imagemDentesSup = {
        dente18: false,
        dente17: false,
        dente16: false,
        dente15: false,
        dente14: false,
        dente13: false,
        dente12: false,
        dente11: false,
        dente21: false,
        dente22: false,
        dente23: false,
        dente24: false,
        dente25: false,
        dente26: false,
        dente27: false,
        dente28: false,
      }
    },
    desativaTodos() {

      this.dentesSelInf = []
      this.dentesSelSup = []
      this.inferior = false
      this.superior = false
      this.imagemDentesSup = {
        dente18: false,
        dente17: false,
        dente16: false,
        dente15: false,
        dente14: false,
        dente13: false,
        dente12: false,
        dente11: false,
        dente21: false,
        dente22: false,
        dente23: false,
        dente24: false,
        dente25: false,
        dente26: false,
        dente27: false,
        dente28: false,
      }
      this.imagemDentesInf = {
        dente48: false,
        dente47: false,
        dente46: false,
        dente45: false,
        dente44: false,
        dente43: false,
        dente42: false,
        dente41: false,
        dente31: false,
        dente32: false,
        dente33: false,
        dente34: false,
        dente35: false,
        dente36: false,
        dente37: false,
        dente38: false,
      }


    }

  }


}

</script>

<style scoped>

.odontograma {
  width: 530px;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 10px;

}

.odontograma-sup {
  border-bottom: dashed 1px #c5c5c5;
}

.odontograma-inf {
  margin-top: 0;
  padding: 4px;
}

.odontograma-dente {
  display: inline-block;
}

.odontograma-dente-inf {
  display: inline-block;
}

.odontogram-top-teeth .odontogram-number {
  margin-top: -5px;
  margin-bottom: -5px;
}

.odontogram-number {
  text-align: center;
  cursor: pointer;


}

.odontogram-number-48 {
  margin-bottom: 5px;
}

.odontogram-number-47 {
  margin-bottom: 5px;
}

.odontogram-number-46 {
  margin-bottom: 6px;
}

.odontogram-number-45 {
  margin-bottom: 1px;
}

.odontogram-number-44 {
  margin-bottom: 4px;
}

.odontogram-number-43 {
  margin-bottom: 4px;
}

.odontogram-number-42 {
  margin-bottom: 4px;
}

.odontogram-number-41 {
  margin-bottom: 2px;
}

.odontogram-number-31 {
  margin-bottom: 4px;
}

.odontogram-number-32 {
  margin-bottom: 4px;
}

.odontogram-number-33 {
  margin-bottom: 5px;
}

.odontogram-number-34 {
  margin-bottom: 4px;
}

.odontogram-number-35 {
  margin-bottom: 2px;
}

.odontogram-number-36 {
  margin-bottom: 5px;
}

.odontogram-number-37 {
  margin-bottom: 6px;
}

.odontogram-number-38 {
  margin-bottom: 5px;
  text-align: center;
}

img {
  margin: 0 2px 0 2px;
  cursor: pointer;
}

.odontograma-dente-inf-48 {
  margin-left: -6px;
}

.odontograma-dente-inf-47 {
  margin-left: -2px;
}

.odontograma-dente-inf-41 {
  padding-left: 5px;
}


</style>
