<template>
  <div>
    <b-dropdown id="dropdown-form" text="informar materiais" ref="dropdown" variant="outline-primary" class="mt-2" block >
      <validation-observer ref="materiais">
        <b-dropdown-form>
          <b-row class="p-1">
            <h6 class="text-primary">Informe o que será enviado para o Laboratório</h6>
          </b-row>
          <hr>
          <template v-for="(material, index) in materiais">
            <b-row v-if="!material.campoAdicional">
              <b-col cols="8">
                <b-form-checkbox
                    class="mb-1"
                    v-model="material.checked"
                >
                  {{ material.nome }}
                </b-form-checkbox>
              </b-col>
              <b-col cols="4" class="float-right">
                <b-form-spinbutton
                    id="quantidade"
                    v-if="material.checked"
                    size="sm"
                    v-model="material.qtd"
                    placeholder="Qtd"
                />
              </b-col>
            </b-row>

            <b-row v-else class="mt-1">
              <b-col cols="12" class="mb-1">
                <validation-provider
                    #default="{ errors }"
                    name="servico"
                    rules="max:50"
                >
                  <b-input-group size="sm">

                    <b-form-input
                        v-model="material.nome"
                        placeholder="campo adicional"
                    >
                    </b-form-input>
                    <b-input-group-append >
                      <b-button @click.prevent="excluirMaterial(index)" variant="outline-danger">
                        <feather-icon icon="Trash2Icon"></feather-icon>
                      </b-button>

                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
          </template>

         <!-- <b-button variant="outline-primary mr-1" size="sm" block @click="addMaterial"><feather-icon icon="PlusIcon"></feather-icon>Adicionar Campo Extra</b-button> -->
          <b-button variant="primary" size="sm" block @click="gravarMateriais">Gravar</b-button>
        </b-dropdown-form>
      </validation-observer>
      <b-dropdown-divider></b-dropdown-divider>
    </b-dropdown>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { BFormSpinbutton, BInputGroup, BInputGroupAppend, BRow, BCol, BDropdown, BDropdownForm,  BFormInput, BFormCheckbox, BButton, BDropdownDivider } from "bootstrap-vue";
import {mapGetters} from "vuex";

export default {
  name: "Materiais",
  components: {
    BFormSpinbutton,
    BInputGroup,
    BInputGroupAppend,
    BRow,
    BCol,
    BDropdown,
    BDropdownForm,
    BFormInput,
    BFormCheckbox,
    BButton,
    BDropdownDivider,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      materiais: [
        { nome: 'Antagonista', qtd: 1, checked: false, campoAdicional: false},
        { nome: 'Modelo Gesso', qtd: 1, checked: false, campoAdicional: false},
        { nome: 'Parafuso Implante', qtd: 1, checked: false, campoAdicional: false},
        { nome: 'Outros', qtd: 1, checked: false, campoAdicional: false},
      ]
    }
  },


  watch: {
    parsedMateriaisNome: {
      handler(newValue) {
        this.materiais = newValue;
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters('materiais', ['parsedMateriaisNome']),
  },

  methods: {

    excluirMaterial(index) {
      this.materiais.splice(index, 1)
    },
    gravarMateriais() {

      this.$refs.materiais.validate().then( success => {
        if(success) {
          let resultado = []
          this.materiais.forEach( v => v.checked || v.campoAdicional && v.nome ? resultado.push({nome: v.nome, qtd: v.qtd}) : '' )
          this.$emit('materiais', resultado)
          this.onClick()
        }
      })

    },
    addMaterial() {
      this.materiais.push({ nome: '', qtd: '', checked: false, campoAdicional: true})
    },
    onClick() {
      // Close the menu and (by passing true) return focus to the toggle button
      this.$refs.dropdown.hide(false)
    }
  }
}
</script>

<style scoped>

</style>
